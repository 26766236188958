@import './variable.scss';

body{
    font-family: $bodyFont;
}

p {
    font-family: $bodyFont;
    color: $darkGray;
    font-size:1em;
    line-height: 1.5;
    padding-bottom: 1em;
    
}

h4{
    font-family: $headerFont;
    font-size:3em;
    @media(min-width:1000px){
        font-size:3.5em
    }
}

h5{
    font-family: $headerFont;
    font-size:2em;
    text-transform: uppercase;
    font-weight:800;
    letter-spacing: 0.25px;
    display:inline-block;
}

.section-title{
    color: $darkGray;
    padding-bottom:0.25rem;
    padding-top:1.5rem;
    border-bottom: 4px solid $primaryColor;
    width:auto;
    margin-left:1rem;
    margin-bottom:1.5rem;
    &.on_primary{
        border-bottom: 4px solid $white; 
    }
    &.on_dark{
        border-bottom: 4px solid $primaryColor; 
    }
    @media (min-width:750px){
        margin-left: 2rem;
    }
}

.on_dark{
    color:$white;
}

.artists {
    background-color:$white;
 &-list{
    display: flex;
    flex-wrap: wrap;
    
    a{
        display:block;
        background: $darkGray;
        width:100vw;
        height:100vw;
        overflow:hidden;
        position:relative;
        @media (min-width:750px){
            width:33.33333vw;
            height:33.33333vw;
        }
        img{
            min-width:100%;
            height:100%;
            opacity:0.75;
            transition:opacity 0.5s;
            z-index: 1;
            position:relative;
        }
        aside{
            position:absolute;
            z-index: 2;
            top: 50%;
            left: 50%;
            font-family: Arial, Helvetica, sans-serif;
            color: $white;
            text-transform: uppercase;
            letter-spacing: 0.25px;
            line-height: 1.25;
            text-align:center;
            transform: translate(-50%, -50%)
        }
        &:hover{
            img{
                opacity:1;
            }
        }
    }
}
}

