@import '../../variable.scss';

.album{
    &_grid{
        width:100%;
        display:flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 2rem 2rem;
    }
    &_grid article{
        width:100%;
        background-color: #ffffff;
        margin-bottom: 2rem;
        img{
            width:100%;
        }
        @media (min-width:750px){
            background-color: transparent;
            width:30%;
        }
    }
    &_art{
        margin-bottom: 0.5rem;
    }
    &_links{
        padding:1rem;
        a{
            display:inline-block;
            text-decoration: none;
            font-family: $headerFont;
            font-weight: 300;
            color:black;
            font-size:1em;
            line-height: 1.25;
            margin-bottom:0.5rem;
            border-bottom: 1px solid transparent;
            &:hover{
                border-bottom: 1px solid $primaryColor;
            }
        }
        @media (min-width:750px){
            padding:1rem 0;
        }
    }
    &_title{
        font-size:1.25em;
        font-weight: 600;
        font-family: $headerFont;
        padding-left: 1rem;
        @media (min-width:750px){
            padding:0;
        }
    }
}