@import '../../variable.scss';
footer{
    background-color: $darkGray;
    padding: 2rem 0;
    color: $white;
    a{
        color:$white;
        font-weight:600;
        &:hover{
            text-decoration:none;
        }
    }
    p{
        margin-left:1rem;
        @media (min-width:750px){
            margin-left: 2rem;
        }
    }
}