@import '../../variable.scss';

.page-home{
    .hero-pad{
       height: 100vw;
       max-height:calc(100vh - 4rem);
    }
    .hero-container{
        height: 100vw;
        background-size: 90%;
        min-width:0;
        min-height: 0;
        max-width: 1100px;
        margin: 0 auto;
        max-height:calc(100vh - 4rem);
        margin-top:3rem;
        @media (min-width:750px){
            margin-top:5rem;
        }
    }
    .hero{
        background-color: $primaryColor;
    }
    &-extra{
        height:1rem;
        width:100%;
        background:$white;
        box-shadow: 0 -1px 20px rgba($darkGray,0.5);
        margin-bottom:-1rem;
    }
}