.hero{
    position:fixed;
    top:0;
    width:100%;
    z-index:-2;
    &-pad{
        padding-bottom: calc(50vh - 5rem);
        @media (min-width:750px) {
            padding-bottom: calc(100vh - 5rem);
        }
    }
    &-container{
        color:transparent;
        min-width:100%;
        min-height:300px;
        padding-bottom:calc(50vh - 5rem);
        background-size: 150%;
        background-repeat: no-repeat;
        background-position: center center;
        @media (min-width:750px) {
            background-size:cover;
            padding-bottom:calc(100vh - 5rem);
        }
    }
}