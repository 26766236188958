@import '../../variable.scss';

.logo_text{
    font-family: $logoFont;
    text-transform: none;
    @media(min-width:750px){
        font-size: 3em;
    }
}
.primary-header{
    position:fixed;
    top:0;
    left:0;
    right:0;
    padding: 1rem;
    background-color: $primaryColor;
    color:$white;
    z-index: 100;
    h5{
        font-weight:300;
        letter-spacing: 0.5px;
    }
    a{
        color: white;
        appearance: none;
        text-decoration: none;
    }
}