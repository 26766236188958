.leilaway{
    .hero-pad{
        padding-bottom:calc(100vh - 18rem);
    }
    .hero-container{
        padding-bottom: calc(100vh - 18rem);
    }
    .about{
        background-color: #d1d1d1;
    }
}