.page-artist{
    .primary-header{
        background-color: black;
    }
}

.discography{
    background-color: #f1f1f1;
}

.andeverythingbetween{
    .about{
        background-color: #d1d1d1;
    }
    .about-image img{
        border-radius: 500px;
        overflow:hidden;
    }
}