@import '../../variable.scss';

.news{
    background-color: $primaryColor;
    &-content{
        padding: 0 2rem;
    }
    a{
        color:#1d1d1d;
        text-decoration: none;
        border-bottom: 1px solid white;
    }
    p{
        color: #1d1d1d;
        font-size: 1.5em;
        line-height: 1.5;
        padding-bottom: 1em;
    }
}