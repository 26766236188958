@import '../../variable.scss';

.about{
    background-color:$primaryColor;
    &-content{
        display: flex;
        flex-wrap: wrap;
    }
    &-image{
        width:100vw;
        padding:1.5rem;
        text-align: center;
        img{
            width:100%;
            max-width: 350px;
        }
        @media (min-width:750px){
            width:33.33333vw;
            margin-top:-3rem;
        }
    }
    article{
        width:100vw;
        padding: 0 1rem;
        font-size:1.25em;
        font-weight:800;
        @media (min-width:750px){
            width:66.666667vw;
            padding: 0 5rem 0 2rem;
        }
        
    }
    a{
        display:inline-block;
        text-decoration: none;
        font-family: $headerFont;
        color:black;
        border-bottom: 1px solid transparent;
        margin-right: 2rem;
        &:hover{
            border-bottom: 1px solid $primaryColor;
        }
    }

}